import React, { useRef, useState } from "react";
import Carousel from "react-slick";
import { css, Global } from "@emotion/core";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import {
  Desktop,
  Tablet,
  TabletThreshold,
  DesktopThreshold,
} from "../../constants/responsive";
import Img from "gatsby-image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Skeleton from "../Skeleton";
import { videos } from "../../constants/videos";
import Play from "../../svgs/icons/play.svg";
import LeftArrow from "../../svgs/icons/left-arrow.svg";
import RightArrow from "../../svgs/icons/right-arrow.svg";
import Modal from "../Modal";

const CustomArrow = ({ arrowStyle, onClick, children }) => {
  return (
    <div
      css={css`
        width: 40px;
        height: 40px;
        position: absolute;
        z-index: 10;
        top: 107px;

        & svg {
          fill: white;
        }

        ${arrowStyle}
      `}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const VideoCard = ({ client, region, imgSrc, onClick }) => {
  const xPositionRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
        cursor: ${isVisible ? "pointer" : "initial"};
        margin-bottom: 0px;

        ${Tablet} {
          max-width: 100%;
          margin-bottom: 30px;
        }
        ${Desktop} {
          & :hover {
            & .overlay {
              opacity: 1;
            }
          }
        }
      `}
      onMouseDown={(e) => {
        xPositionRef.current = e.screenX;
      }}
      onClick={(e) => {
        return isVisible && Math.abs(e.screenX - xPositionRef.current) === 0
          ? onClick()
          : null;
      }}
    >
      <div
        css={css`
          position: absolute;
          z-index: 11;
          max-width: 450px;
          width: 100%;
          aspect-ratio: 16/9;

          ${Tablet} {
            width: 354px;
          }

          ${Desktop} {
            width: 373px;
          }
        `}
      >
        <Skeleton
          skeletonStyle={css`
            width: 100%;
            height: 100%;
            z-index: 8;
            top: 0;
            left: 0;
            position: absolute;
            transition: opacity 0.3s ease;
            opacity: ${isVisible ? 0 : 1};
          `}
        />

        <div
          className="overlay"
          css={css`
            width: 100%;
            height: 100%;
            ${Desktop} {
              transition: opacity 0.2s ease-in-out;
              opacity: 0;
            }
          `}
        >
          <Play
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-30px, -30px);
              width: 60px;
              height: 60px;
              z-index: 5;
              & path {
                stroke: #fff;
                stroke-width: 8;
                stroke-linejoin: round;
              }
            `}
          />
          <div
            css={css`
              position: relative;
              width: 100%;
              height: 100%;
              background: #000;
              z-index: 4;
              opacity: ${isVisible ? 0.5 : 0};
            `}
          />
        </div>
      </div>

      <div
        css={css`
          width: 100%;
          display: flex;
          aspect-ratio: 16/9;
        `}
      >
        <Img
          fluid={imgSrc}
          loading="eager"
          style={{
            width: "100%",
            position: "relative",
            zIndex: 10,
          }}
          fadeIn={false}
          onLoad={() => setIsVisible(true)}
        />
      </div>

      <h3
        css={css`
          color: #fff;
          font-weight: bold;
          margin-top: 20px;
          margin-bottom: 5px;
          font-size: 18px;
          text-align: center;

          ${Tablet} {
            margin-top: 30px;
            margin-bottom: 5px;
          }
          ${Desktop} {
            font-size: 20px;
          }
        `}
      >
        <FormattedHTMLMessage id={`video.${client}`} />
      </h3>
      <h4
        css={css`
          color: #fff;
          font-weight: lighter;
          margin-bottom: 10px;
        `}
      >
        <FormattedHTMLMessage id={`video.${region}`} />
      </h4>
    </div>
  );
};

const VideoCarousel = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    fade: false,
    arrows: false,
    prevArrow: (
      <CustomArrow
        arrowStyle={css`
          left: 0px;
        `}
      >
        <LeftArrow
          css={css`
            width: 100%;
            height: 100%;
          `}
        />
      </CustomArrow>
    ),
    nextArrow: (
      <CustomArrow
        arrowStyle={css`
          right: 0px;
        `}
      >
        <RightArrow
          css={css`
            width: 100%;
            height: 100%;
          `}
        />
      </CustomArrow>
    ),
    responsive: [
      {
        breakpoint: DesktopThreshold,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: TabletThreshold,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [modalOpen, setModalOpen] = useState(false);
  const video = useRef(null);

  return (
    <>
      <Global
        styles={css`
          html {
            overflow-y: ${modalOpen ? "hidden" : "visible"};
          }
        `}
      />

      <Modal video={video} modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <h2
          css={css`
            text-align: center;
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 40px;

            ${Desktop} {
              font-size: 36px;
              margin-bottom: 50px;
            }
          `}
        >
          {<FormattedHTMLMessage id="video.title" />}
        </h2>

        <div
          css={css`
            width: 100%;
            height: 100%;
            margin-bottom: 0px;

            & .slick-slide > div {
              margin: 0 10px;
            }
            & .slick-list {
              margin: 0 -9.5px;
            }
          `}
        >
          <Carousel
            {...settings}
            css={css`
              & .slick-dots {
                display: flex !important;
                flex-wrap: wrap;
                justify-content: center;
                width: 60% !important;
                margin: 0 auto !important;
                bottom: -30px;
                position: unset !important;

                ${Tablet} {
                  bottom: -25px;
                }
              }
              & li {
                margin-left: 5px;
                margin-right: 5px;
                margin-bottom: 5px;
                ${Tablet} {
                  margin-top: 0;
                }
              }
              & li button:before {
                color: White !important;
                transition: opacity 0.3s ease;

                ${Tablet} {
                  font-size: 14px;
                }
                ${Desktop} {
                  font-size: 16px;
                }
              }
            `}
          >
            {videos.map(({ id, client, region, type }) => (
              <VideoCard
                key={id}
                client={client}
                region={region}
                imgSrc={data[client].childImageSharp.fluid}
                onClick={() => {
                  setModalOpen(true);
                  video.current = { id, type };
                }}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default VideoCarousel;
